@import '../../styles/styles/variables';


[data-barba-namespace="leistungsPage"] {

    .intro-text,
    #renderings,
    #fachplanung,
    #programming {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }


   

    h3 {
        margin-bottom: 0;
    }


}

// @media(min-width: 768px) {
//     [data-barba-namespace="leistungsPage"] {
        
//     }
// }


@media(min-width: 992px) {
    [data-barba-namespace="leistungsPage"] {

        .intro-text,
        #renderings,
        #fachplanung,
        #programming {
            padding-top: 7rem;
            padding-bottom: 7rem;
        }

        h2 {
            margin-top: 0
        }
        h3 {
            
            margin-top: 0 !important;
        }
    }
}


/// Font Size Corrections SM
@media(max-width: 767px){
    h2{
        font-size: 2.25rem;
    }
    h3{
        font-size: 1.75rem;
    }
}