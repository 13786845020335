#fsi-cursor {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    color: white;
    z-index: 250;
    pointer-events: none;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1;
    transform-origin: center center;
    will-change: transform;
    text-transform: uppercase;
    div{
        padding-right: 3px;
    }
}