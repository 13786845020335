 @import '../../styles/styles/variables';


 .myrImgWrp{
     position:relative;
    img{
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
     }
     span{
         position:absolute;
         top: 0;
         left:0;
        
         height: 100%;
     }
     .light1{
         background-color: $lasBlueRibbon;
         width: 20px;
     }
     .light2{
         background-color: $lasBlue;
         width: 15px;
     }
     .light3{
         background-color: $lasElectricViolet;
         width: 10px;
     }
     .light4{
         background-color: $lasPsychodellicPurple;
        width: 12px;
        }
     .light5{
         background-color: $lasElectricViolet;
     }
     .light6{
         background-color: $lasBlueRibbon;
         width: 15px;
     }
     .light7{
         background-color: $lasBlue;
         width: 20px;
     }
 }