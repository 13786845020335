.stampWrp{
    padding-top: 2rem;
    .stampOuter{
        margin: 0 auto;
        position: relative;
        width: 20vw;
        height: 20vw;
        background-repeat: no-repeat;
        background-position: center center;
        .stampInner{
            top: 28%;left: 28%;right: 28%;bottom: 28%;
            position:absolute;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
}

@media(min-width:993px){
    .stampWrp{
        .stampOuter{
            margin: 0;
            width: 8.5vw;
            height: 8.5vw;
        }
    }
}