
$gold: #d9a86c;
$gold_bright: #f2dfbb;
$gold_dark: #352e22;


// Origin
// $blue: #98BDE2;
// $blue_bright: #CCE0EA;
// $bgGrey: #1e1e1e;

// Aggressive Windows
// $blue: #1B3EFE;
// $blue_bright: #198AFF;
// $bgGrey: #000422;

// Materialize Blue Grey
$blue_bright:#b0bec5;
$blue: #78909c;

$blue_dark: #37474f ;
$bgGrey: #263238;


//LAS
$lasBlue:  #3735FF;
$lasBlueRibbon: #006af9;
$lasNeonMalibu: #6BD6FE;
$lasPsychodellicPurple: #dc00fe;
$lasElectricViolet: #8c00fe;
$lasRoyalBlue: #5319fb;


 

 
