.scroll-down-btn {
    --button-color: #333;
    cursor: pointer;
    width: 30px;
    height: 55px;
    border: 2px solid var(--button-color);
    border-radius: 60px;
    position: absolute;
    bottom: 125px;
    z-index: 5;

    &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 10px;
        left: calc(50% - 4px);
        background-color: var(--button-color);
        border-radius: 50%;
        opacity: 1;
        animation: wheel 2s infinite;
        -webkit-animation: wheel 2s infinite;
    }

    &.paused {
        &::before {
            animation-play-state: paused;
        }
    }
}

@keyframes wheel {
    to {
        opacity: 0;
        transform: translateY(25px);
    }
}

@media(min-width: 500px) { 
    .scroll-down-btn {
        bottom: 65px;
}}

@media(min-width: 768px) { 
    .scroll-down-btn {
        width: 35px;
        height: 64px;
        border: 3px solid var(--button-color);
        &::before{
			left: calc(50% - 4.25px);
		}
        @keyframes wheel {
            to {
                opacity: 0;
                transform: translateY(30px);
                // top:60px;
            }
        }
    }
}