@import '../../styles/styles/variables';

[data-barba-namespace="aboutPage"] {

    section.intro-text {
        padding-top: 6rem;
        padding-bottom: 4rem;
    }
    section.person   {
        padding-top: 3rem;
        padding-bottom: 3rem;
        .subtitle{
        padding-bottom: 3rem; 
        }
        h3{
            letter-spacing: 2px;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        p{
            line-height: 2;
        }
        a.blue-link{
            color: white;
            &:hover{
                color: $lasElectricViolet
            }
        }
    }
    section.presse{
        ul li{
            a.white-link{
                font-weight: 400;
            }
        }
    }
    .hidden-img{
        position:absolute;
        visibility: hidden;
    }

}


@media(min-width: 768px) {
    [data-barba-namespace="aboutPage"] {
    section.person{
        .subtitle{
        padding-bottom: 6rem; 
        text-align: center;
        }
    }
    section.presse {
        h2{margin-top: 16px;}
        ul{
            margin-top: 16px;
            list-style: none;
            padding-left: 0;
            li{
                padding-bottom: 1.25rem;
            }
        }
    }}}

@media(min-width: 993px) {
    [data-barba-namespace="aboutPage"] {
        section.person, section.intro-text, section.presse {
            padding-top: 7rem;
            padding-bottom: 7rem;
            .subtitle{
                padding-bottom: 8rem; 
            }
        }
        section.intro-text {
            padding-top: 14rem;
            padding-bottom: 7rem;
            p{
                font-size: 1.5vw;
            }
        }
        section.person{
            h3{
                margin-top: -.3vw;
            }
            p{
                font-size: 1.25vw;
            }
        }
        section.presse {
            h2{margin-top: 62px;}
            ul{
                margin-top: 62px;
                list-style: none;
                padding-left: 0;
                li{
                    font-size: 1.5vw;
                    line-height: 1.45;
                    padding-bottom: 2rem;
                    a.white-link{
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

@media(min-width: 1920px) {
    section.person, section.presse{
        .col-lg-4{
            width: 25%;
        }
    }

}

