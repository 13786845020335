.bg-wrp-fixed {
    position: fixed;
    display:none;
    width: 100%;
    height: 100vh;
    z-index: -1;
    svg {
        width: 100%;
        height: 100%;
    }
}