@import './../../styles/styles/variables';

.blende-preLoader {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
    background-color: black ;
    visibility: hidden;
    width: 100%;
    height: 100%;
    transform-origin: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0%;

    // bugfix for safari
    overflow:hidden;
   
    .color-blend {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 2%;
        height: 100%;
        z-index: -1;

        &.col-blend-1 {
            background-color: $lasBlue;
            width: 2.5%
        }

        &.col-blend-2 {
            background-color: $lasBlueRibbon;
            width: 2.25%
        }

        &.col-blend-3 {
            background-color: $lasElectricViolet;
            width: 2%
        }

        &.col-blend-4 {
            background-color: $lasNeonMalibu;
            width: 1%
        }

        &.col-blend-5 {
            background-color: $lasPsychodellicPurple;
            width: .25%
        }

        &.col-blend-6 {
            background-color: $lasBlue;
            width: .25%
        }

        &.col-blend-7 {
            background-color: $lasBlueRibbon;
            width: .35%
        }

        &.col-blend-8 {
            background-color: $lasElectricViolet;
            width: .4%
        }

        &.col-blend-9 {
            background-color: $lasBlue;
            width: .5%;
            left: 0%;
        }
        &.last-blend{
            background-color: $lasPsychodellicPurple;
            width: 10%;
            left: -10%;
        }
        
        .blende-preLoader--logoWrp {
            position: relative;
            width: 50%;
            overflow: hidden;

            svg {
                width: 100%;
                height: auto;
            }

            svg#orig {
                fill: $gold;
                position: relative;
                z-index: 2;
                clip-path: polygon(0 95%, 100% 95%, 100% 100%, 0% 100%);
            }

            svg#clone {
                fill: white;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}






#prelLogo {
    width: 60%;
    height: auto;
   

    .letters {
        path {
            filter: drop-shadow(10px 14px 21px rgba(255, 255, 255, .5));
        }
    }
    .stroke {
        filter: drop-shadow(10px 11px 9px rgba(255, 255, 255, 0.2));
    }
}

// #canvas-container{
//     // background-color: black;
// }