.full-screen-starter {
    background-color: black;
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
        align-self: center;
        position: relative;
        z-index: 250;
    }

    svg {
        position: relative;
        z-index: 5;
        width: 60%;
        height: auto;

    }

    .bgImgWrp {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 2;
            // filter: contrast(1.5) brightness(1.25);

            // &.img-color {
            //     clip-path: circle(250px at 50% 50%);
            // }

            // &.img-grey {
            //     z-index: 1;
            //     filter: grayscale(.8) brightness(.6);
            // }
        }

    }

    .logo {
        width: 60%;
        height: auto;
        position: relative;
        z-index: 3;
        filter: drop-shadow(10px 14px 21px rgba(255, 255, 255, .2));

    }

    .content {
        width: 100%;
        text-align: center;

        p {
            text-align: right;
            margin-top: -1.5vw;
            padding-right: .9vw;
        }
    }
}

@media(min-width:768px) {
    .full-screen-starter {
        .logo {
            width: 50%;
        }
        svg{
            width: 50%;
        }
    }
}