@import '../../styles/styles/variables';
@import '../../styles/styles/fontsizes';

header {
    padding-top: 0;
    position: absolute;
    z-index: 152;
    width: 100%;

    img {
        margin-top: 18px;
        min-width: 120px;
        width: 9vw;
        height: auto;

        path {
            fill: black !important;
        }
    }

    .nav-wrp {
        display: none;

        .desktop-nav {
            list-style: none;
            margin-top: 2.95vw;
            display: inline-block;
            line-height: .9;

            li {
                display: inline-block;

                &:last-child {
                    padding-right: calc(3vw + 40px);
                }
            }
        }
    }

}

.mob-nav-toggler {
    // background-color: bisque;
    position: fixed;
    width: 100%;
    min-height: 120px;
    z-index: 501;
    pointer-events: none;


    .hamburger-btn,
    .close-btn,
    .back-btn {
        position: absolute;
        top: 20px;
        right: 3vw;
        display: inline-block;
        cursor: pointer;
        line-height: 0;
        pointer-events: all;

        svg {
            min-width: 30px;
            width: 2vw;
            height: auto;

            path,
            rect {
                transition: fill .35s;
            }
        }

        &:hover {

            svg path,
            svg rect {
                fill: $lasPsychodellicPurple !important;
            }
        }
    }



    .close-btn,
    .back-btn {
        visibility: hidden;
        pointer-events: none;

        span {
            left: 0;
            opacity: .9;
            transform-origin: center;
            top: 10px;

            &:first-child {
                transform: rotate(45deg);
            }

            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }

    .back-btn {
        svg {
            min-width: 24px;
        }
    }

}

//Blende
.blende-navi {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    visibility: hidden;
    z-index: 500;

    .close-btn {
        position: absolute;
        z-index: 2;
        color: white;
        cursor: pointer;
        top: 2rem;
        right: 2rem;
        visibility: hidden;
    }

    .img-wrp {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        height: auto;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    ul.toggle-nav {
        @extend .foz-m;
        width: 80%;
        position: absolute;
        padding-left: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-family: "Anaheim", sans-serif;
        line-height: 1;

        li {
            margin-bottom: 1.25rem;
            overflow: hidden;
            text-align: center;
            padding-bottom: 0;

            a {
                display: inline-block;
            }


            a:after {
                bottom: 0;
            }

            &:last-of-type {
                margin-top: 1.75vw;

                a {
                    @extend .foz-s;
                    text-transform: lowercase;
                    font-style: italic;
                }
            }

            &:first-child a {
                width: 100%;
            }
        }

        .blob-wrp {
            pointer-events: none;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: -1;
            z-index: -1;
            transform: scaleY(1.75);
        }

        svg:not(:root) {
            width: 100%;
            overflow: visible;
            height: auto;
            width: 100%;
            // filter: drop-shadow(0px 6px 6px rgba(140, 0, 254, 0.95));
            height: auto;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            path {
                stroke-width: 1px !important;
            }
        }

    }
}

// mobile Nav Toggle Nav

.nav-li-underline {
    li {
        // display: inline-block;
        padding-right: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        list-style: none;

        a {
            color: white;
            text-decoration: none;
            position: relative;

            &:after {
                content: "";
                transform: scaleX(0);
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -5px;
                left: 0;
                background-color: white;
                transform-origin: bottom right;
                transition: transform .45s ease-out;
            }

            &:hover:after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }

            img {
                width: 60%;
                margin-left: auto;
                transition: transform .3s;
            }
        }

        &:first-child {
            a::after {
                display: none
            }

            a:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
}

@media(min-width:500px) {
    .blende-navi ul.toggle-nav .blob-wrp {
        transform: scaleY(1);
    }
}


@media(min-width:993px) {
    header {
        img {
            margin-top: 57px;
            min-width: 120px;
        }

        .nav-wrp {
            display: block;

            svg {
                margin-top: 2.25vw;
            }
        }
    }

    .nav-li-underline {
        li {
            a {
                img {
                    width: 45%;
                }
            }
        }
    }


    .blende-navi {
        ul.toggle-nav {
            width: 60%;
        }
    }

    .mob-nav-toggler {

        .hamburger-btn,
        .close-btn,
        .back-btn {
            top: 57px;

            svg {
                min-width: 0;
            }
        }
    }
}

@media(min-width:1200px) {
    .blende-navi {
        ul.toggle-nav {
            width: 50%;
        }
    }
}