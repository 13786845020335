// Idea follow until 992px a rem Concept
// Then switch to vw



.foz-s {
    font-size: 1rem;
}

.foz-m {
    font-size: 1.25rem;
}

.foz-l {
    font-size: 2rem;
}

.foz-xl {
    font-size: 2.5rem;
}

.foz-xxl {
    font-size: 22vw;
}


@media(min-width: 576px) {}


@media(min-width: 768px) {
    .foz-s {
      
        font-size: #{"max(1vw, 16px)"}
    }

    .foz-m {
        font-size: #{"max(1.25vw, 20px)"}
    }

    .foz-l {
        font-size: 2vw;
        font-size: #{"max(2vw, 24px)"}
    }

    .foz-xl {
        
        font-size: #{"max(4vw, 32px)"}
    }

    .foz-xxl{
        font-size: 6vw;
    }
}

@media(min-width: 1200px) {}