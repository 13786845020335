@import '../../styles/styles/variables';


[data-barba-namespace="homePage"] {
    section.intro-text {
        padding-top: 6rem;
        padding-bottom: 6rem;
        color: white;
        position: relative;

        h2 {
            margin-top: 0;
        }

        p {
            font-family: InterVariable, serif;
        }

    }

    h3 {
        margin-bottom: 0;
    }
}

@media(min-width:768px) {
    [data-barba-namespace="homePage"] {
        .full-screen-starter {
            .logo {
                margin-left: .45%
            }
        }

        .header h3 {
            margin-bottom: 1em;
            margin-top: 0 !important;
        }
    }
}

@media(min-width: 992px) {
    [data-barba-namespace="homePage"] {
        section.intro-text {
            padding-top: 12rem;
            padding-bottom: 16rem;

            p {
                margin-top: -5rem;
            }
        }
    }
}