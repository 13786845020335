@import "./variables";
@import "./fontsizes";

// @font-face {
//   font-family: "InterVariable";
//   font-style: normal;
//   font-display: swap;
//   font-weight: 100 900;
//   src: url("../scripts/inter-variable-regular.woff2") format("woff2");
// }

// @font-face {
//   font-family: "Anaheim";
//   font-style: normal;
//   font-display: swap;
//   font-weight: 400;
//   src: url("../scripts/anaheim-latin-400-normal.woff2") format("woff2");
// }

html {
  position: relative;
}

body {
  font-family: "InterVariable", sans-serif;
  position: relative;
  min-height: 101vh;
  color: white;
}

h1 {
  @extend .foz-xxl;
  font-family: "Anaheim", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.3vw;
}

h2 {
  @extend .foz-xl;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 0;
  font-family: "Anaheim", sans-serif;
}

h3 {
  @extend .foz-l;
  font-family: "Anaheim", sans-serif;
}

h4 {
  @extend .foz-s;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

p {
  @extend .foz-s;
  line-height: 1.6;
  font-weight: 300;
  // font-family: "Anaheim", sans-serif;
}

ul,
ol {
  @extend .foz-s;
  line-height: 1.6;
}

* {
  box-sizing: border-box;
}

//helper

.container-fluid {
  padding-left: 3vw !important;
  padding-right: 3vw !important;

  &.container-full {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.resp-img {
  width: 100%;
  height: auto;
}

.hand {
  font-family: "WindSong";
  font-weight: 400 !important;

  color: $gold;
}

footer {
  position: relative;
  z-index: 1;
  padding-top: 5rem;
  padding-bottom: 2rem;
  color: $blue_bright;

  p {
    margin-bottom: 0.5rem;
  }

  span.copy {
    opacity: 0.55;
  }

  a {
    color: $blue_bright;
    text-decoration: none;
    transition: color 0.75s;

    &:hover {
      color: white;
    }
  }
}

@media (min-width: 768px) {
  .container-fluid.container-content {
    padding-left: 15vw !important;
    padding-right: 3vw !important;
  }

  ul {
    li {
      padding-bottom: 0.35rem;
    }
  }
}

@media (min-width: 1200px) {
}

#canvas-container {
  overflow: hidden;
  position: fixed;
  width: 100%;
  left: 0;
  height: 100.001vh;
  z-index: 250;
  opacity: 1;
  visibility: hidden;

  .blende {
    width: 100%;
    height: 100%;
    transform-origin: bottom;

    visibility: hidden;
    position: relative;
    z-index: 55;

    #blende-logo {
      width: 50%;
      height: auto;
      opacity: 0;
    }

    .inner-blende {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 16.67%;

      &.inner-blende-1 {
        left: 0;
        background-color: $lasBlueRibbon;
        background-image: linear-gradient(
          to right,
          black 0%,
          black 90%,
          $lasBlueRibbon 90%,
          $lasBlueRibbon 100%
        );
      }

      &.inner-blende-2 {
        left: 16.66666%;
        background-color: $lasBlue;
        background-image: linear-gradient(
          to right,
          black 0%,
          black 93%,
          $lasBlue 93%,
          $lasBlue 100%
        );
      }

      &.inner-blende-3 {
        left: 33.3333333%;
        background-image: linear-gradient(
          to right,
          black 0%,
          black 98%,
          $lasElectricViolet 98%,
          $lasElectricViolet 100%
        );
      }

      &.inner-blende-4 {
        left: 49.99999%;
        background-color: $lasPsychodellicPurple;
        background-image: linear-gradient(
          to right,
          black 0%,
          black 96%,
          $lasPsychodellicPurple 96%,
          $lasPsychodellicPurple 100%
        );
      }

      &.inner-blende-5 {
        left: 66.666666%;
        background-color: $lasNeonMalibu;
        background-image: linear-gradient(
          to right,
          black 0%,
          black 94%,
          $lasNeonMalibu 94%,
          $lasNeonMalibu 100%
        );
      }

      &.inner-blende-6 {
        left: calc(66.666666% + 16.66666%);
        // background-color: $lasBlue;
        background-image: linear-gradient(
          to right,
          black 0%,
          black 94%,
          $lasBlue 94%,
          $lasBlue 100%
        );
      }
    }
  }
}

//helper

//Smoothy Image wrap
.smoothyImgWrp {
  overflow: hidden;
  position: relative;

  img {
    left: 0;
    width: 100%;
    object-fit: cover;
    position: absolute;
    will-change: transform;
  }
}

a.blue-link {
  text-transform: uppercase;
  color: $lasNeonMalibu;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.25s;

  svg {
    vertical-align: middle;
    fill: $lasNeonMalibu;
    display: inline-block;
    margin-right: 10px;
    width: auto;
    height: 1rem;
    transition: fill 0.25s;
  }

  &:hover {
    color: lighten($lasPsychodellicPurple, 6%);

    svg {
      fill: lighten($lasPsychodellicPurple, 6%);
    }
  }

  &.tiny {
    text-transform: none;
    font-weight: 400;
  }
}

a.white-link {
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.25s;

  svg {
    vertical-align: middle;
    fill: white;
    display: inline-block;
    margin-right: 10px;
    width: auto;
    height: 1rem;
    transition: fill 0.25s;
  }

  &:hover {
    color: lighten($lasElectricViolet, 6%);

    svg {
      fill: lighten($lasElectricViolet, 6%);
    }
  }

  &.tiny {
    text-transform: none;
    font-weight: 400;
    text-decoration: underline;
  }
  &.sm {
    text-transform: none;
    font-weight: 300;
    line-height: 1.75;
  }
}

@media (min-width: 992px) {
  a.gold-link {
    svg {
      height: 1vw;
    }
  }
}

// dark Theme
body.dark-theme {
  background: $bgGrey;
  color: white;

  header {
    svg {
      path {
        fill: white !important;
      }
    }
  }

  footer {
    span.copy {
      opacity: 0.55;
    }

    a {
      color: $blue_bright;
    }
  }
}

// simple Header
header {
  position: absolute;
  padding-top: 2rem;
  z-index: 250;

  svg {
    width: 6vw;
    min-width: 70px;
  }

  padding-bottom: 2rem;
}

.single-page-logo {
  width: 60%;
  height: auto;

  .letters {
    path {
      filter: drop-shadow(10px 14px 21px rgba(255, 255, 255, 0.5));
    }
  }

  .stroke {
    filter: drop-shadow(10px 11px 9px rgba(255, 255, 255, 0.2));
  }
}
