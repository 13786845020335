@import '../../styles/styles/variables';

[data-barba-namespace="metaPage"] {
    .full-screen-starter {
        background: transparent;
    }

    section.meta-text {
        padding-top: 12rem;
        padding-bottom: 4rem;

        h2 {
            margin-top: 3rem;
        }

        &.contact-text {

            ul,
            h2 {
                margin-top: 0;
                margin-bottom: .25em;
            }

            ul {
                list-style: none;
            }
        }
    }

    ol,
    ul {
        li {
            padding-bottom: 1em;
        }
    }

    ol ul>li {
        padding-bottom: .25em;
    }

    h3 {
        margin-bottom: .5em;
    }
    h4 {
        margin-top: 3em;
    }
    section.contacts{
        h3{
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        a.white-link {
            &:hover{
                color: lighten($lasPsychodellicPurple, 6%);

                svg {
                    fill: lighten($lasPsychodellicPurple, 6%);
                }
            }
    }
}}


@media(min-width: 993px) {
    [data-barba-namespace="metaPage"] {

        section.meta-text {
            padding-top: 14rem;
            padding-bottom: 7rem;
            ul {
                margin-top: 1.25em;
            }
        }
    }

    section.contacts {
        padding-top: 7rem;
        text-align: center;
        padding-bottom: 7rem;
        span.name{
            display: inline-block;
            margin-top: 0;
        }
    }
}


/// Font Size Corrections SM

@media(max-width: 767px) {
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.75rem; 
    }
}