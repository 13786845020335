 // Boilerplates";
 @import '../../styles/styles/fontsizes';

 #robbygal {
     h1 {
         text-transform: uppercase;
     }


     h2 {
         text-align: left;
         line-height: .85;
         margin-bottom: 0;
         margin-top: 4rem;
         word-break: break-word;
     }



     .itemSec {
         margin-bottom: 6rem;

         .itemSec__head {
             margin-bottom: 0;
         }

         .header {
             margin-bottom: 1rem;
         }

         .contCol {
             display: flex;
             flex-direction: column;
             justify-content: space-between;


             .shorty {
                 display: none;
                 transform: rotate(-90deg);
                 width: 0;
                 height: 0;
                 transform-origin: top left;
                 position: relative;

                 a {
                     color: white;

                     &:hover {
                         color: $lasPsychodellicPurple
                     }
                 }

                 span {
                     @extend .foz-xl;
                     display: inline-block;
                     position: absolute;
                     font-weight: 600;
                     line-height: .75;
                 }
             }
         }

         // &.lImg {}

         &.rImg {
             .contCol {
                 .shorty {
                     span {
                         top: 35vw;
                     }
                 }
             }
         }
     }

     #scrollPos {
         position: fixed;
         bottom: 5vw;
         right: 5vw;
     }

   

 }

 @media(min-width:768px) {
     #robbygal {
         h2 {
             margin-bottom: 12vh;
             text-align: center;
         }

         h3 {

             margin-bottom: 1rem;
         }

         .itemSec {
             margin-bottom: 16rem;

             .itemSec__head {
                 margin-bottom: 3rem;
             }

             .header {
                 margin-bottom: 0;
             }

             .contCol .shorty {
                 display: block;
             }

             &.lImg {
                 .contCol {
                     padding-left: 1vw;

                     .shorty {
                         transform: rotate(-90deg) translateY(-5px);
                        
                     }
                 }
             }

             &.rImg {
                 .contCol {
                     padding-right: 1vw;
                 }
             }
         }
     }
 }

 @media(min-width:993px) {
     #robbygal {
         .itemSec {
             &.lImg {
                 .contCol {
                     .shorty {
                         transform: rotate(-90deg) translateY(-30px);
                     }
                 }
             }
         }
     }
 }