.handcrafted {
    position: relative;
  
    overflow: hidden;
    .back-fx-exact {
        position: absolute;
        top: 12vh;
        left:0;
        width: 100%;
        // background: rgba(255,255,255,.15);
        font-size: 8.25vw;
        font-weight: 600;
        span{
            display: inline-block;
            font-family: "CinzelVariable", serif;
            text-transform: uppercase;
            color: black;
            letter-spacing: -.5vw;
        }
    }
    .col-img {
        position: relative;

        h2 {
            position: absolute;    top: 2rem; left:0;
            z-index: 2;
            margin-top: 0;
            font-size: 5.5vw;
            text-align: center;
            font-family: "CinzelVariable", serif;
            text-align: center;
            width: 100%;
        
            line-height: 1;
            text-transform: uppercase;
        }

       
    }

   .col-sub {
            .hand {
                text-align: center;
                
            }
        }
        .description{
            text-align: center;
        }
}

@media(min-width:993px){
    .handcrafted {
        .back-fx-exact {
            top: 17rem;
        }
    }
}