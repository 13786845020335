@import '../../styles/styles/variables';

[data-barba-namespace="modalPage"] {
    section#person {
        padding-top: 10rem;
        padding-bottom: 6rem;

        h2 {
            margin-top: 0;
        }

        span.name {
            font-weight: 600;
            display: inline-block;
            padding-top: 1rem;
            text-transform: uppercase;
            letter-spacing: .5px;
        }

        .smoothyImgWrp {
            margin-bottom: 2.5rem;
        }
    }

}




@media(min-width: 992px) {
    [data-barba-namespace="modalPage"] {
        section#person {
            padding-top: 12rem;
            padding-bottom: 7rem;

            .smoothyImgWrp {
                margin-top: .6vw;
            }

            .smoothyImgWrp {
                margin-bottom: 0rem;
            }
        }

    }
}