// Pages

@import "./styles/normalize.scss";
@import "bootstrap/dist/css/bootstrap-grid";
@import "./styles/boilerplate";
@import "./styles/fontsizes";

@import "../ui-components/components.scss";

// Pages
@import "../pages/homePage/homePage.scss";
@import "../pages/aboutPage/aboutPage.scss";
@import "../pages/leistungsPage/leistungsPage.scss";
@import "../pages/metaPage/metaPage.scss";
@import "../pages/modalPage/modalPage.scss";
